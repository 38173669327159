import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export interface BlogHeroProps {
  className?: string;
  image: IGatsbyImageData;
}

export const BlogHero = (props: BlogHeroProps) => {
  const { className = "", image } = props;
  return (
    <section className={`${className}`}>
      <GatsbyImage className="h-80 lg:h-auto w-full" image={image} alt={"blog hero image"} />
    </section>
  );
};
