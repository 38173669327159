import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import { BlogHeader } from "../../components/Blog/BlogHeader/BlogHeader";
import { BlogHero } from "../../components/Blog/BlogHero/BlogHero";
import { Container } from "../../components/Shared/Container/Container";
import { ContentRenderer } from "../../components/Shared/ContentRenderer/ContentRenderer";
import Layout from "../../components/Shared/Layout/Layout";
import { MessageFormSection } from "../../components/Shared/MessageFormSection/MessageFormSection";

const asArticle = (article: any): Article.Article => article;

export interface BlogPageProps {
  className?: string;
  data: any;
}

export const query = graphql`
  query getBlog($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      content
      date
      excerpt
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      topic
      title
      slug
      author {
        fullName
      }
    }
  }
`;

export const BlogPage = (props: BlogPageProps) => {
  const {
    className = "",
    data: { strapiArticle },
  } = props;

  const article = asArticle(strapiArticle);

  return (
    <Layout className={`${className}`}>
      <BlogHero image={getImage(article.image as any)} />
      <BlogHeader {...article} />
      <Container className="py-16">
        <ContentRenderer source={article.content} />
      </Container>
      <MessageFormSection className="mt-8" />
    </Layout>
  );
};

export default BlogPage;
